import { BN, type Program } from "@coral-xyz/anchor";
import {
	ASSOCIATED_TOKEN_PROGRAM_ID,
	TOKEN_PROGRAM_ID,
	getAssociatedTokenAddressSync,
} from "@solana/spl-token";
import { PublicKey, SystemProgram, Transaction } from "@solana/web3.js";
import type { VoteRewardParams } from ".";
import type { Ve33 } from "../types/ve_33";

export const vote_reward = async (
	program: Program<Ve33>,
	params: VoteRewardParams,
) => {
	const {
		lockId,
		pool,
		epoch,
		authority,
		tokenMintA,
		tokenMintB,
		tokenVaultA,
		tokenVaultB,
	} = params;
	const [votePDA] = PublicKey.findProgramAddressSync(
		[Buffer.from("vote"), new BN(epoch).toArrayLike(Buffer, "le", 8)],
		program.programId,
	);

	const [voteDataPDA] = PublicKey.findProgramAddressSync(
		[
			Buffer.from("vote_data"),
			pool.toBuffer(),
			new BN(epoch).toArrayLike(Buffer, "le", 8),
		],
		program.programId,
	);

	const voteData = await program.account.voteData.fetch(voteDataPDA);
	if (!voteData) {
		throw new Error("Vote data not found");
	}

	const [voteEscrowPDA] = PublicKey.findProgramAddressSync(
		[
			Buffer.from("vote_escrow"),
			authority.toBuffer(),
			new BN(lockId).toArrayLike(Buffer, "le", 8),
			new BN(epoch).toArrayLike(Buffer, "le", 8),
		],
		program.programId,
	);

	const associatedTokenA = getAssociatedTokenAddressSync(tokenMintA, authority);
	const associatedTokenB = getAssociatedTokenAddressSync(tokenMintB, authority);

	const transaction = new Transaction();
	const tx = await program.methods
		.claimReward(pool)
		.accounts({
			vote: votePDA,
			voteData: voteDataPDA,
			tokenMintA,
			tokenMintB,
			voteEscrow: voteEscrowPDA,
			associatedTokenA,
			associatedTokenB,

			tokenVaultA,
			tokenVaultB,

			funder: authority,
			tokenProgram: TOKEN_PROGRAM_ID,
			associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
			systemProgram: SystemProgram.programId,
		})
		.instruction();

	transaction.add(tx);

	voteData.bribesInfos.map(async (bribe) => {
		if (bribe.mint.equals(new PublicKey(0))) {
			return;
		}

		const associatedTokenAccount = getAssociatedTokenAddressSync(
			bribe.mint,
			authority,
		);
		const ix = await program.methods
			.claimBribes(pool)
			.accounts({
				vote: votePDA,
				voteData: voteDataPDA,
				voteEscrow: voteEscrowPDA,
				tokenBribesMint: bribe.mint,
				tokenBribesVault: bribe.vault,
				associatedTokenAccount,

				funder: authority,
				tokenProgram: TOKEN_PROGRAM_ID,
				associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
				systemProgram: SystemProgram.programId,
			})
			.instruction();

		transaction.add(ix);
	});

	return transaction;
};
