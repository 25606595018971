import { BN, type Program } from "@coral-xyz/anchor";
import {
	ASSOCIATED_TOKEN_PROGRAM_ID,
	TOKEN_PROGRAM_ID,
	getAssociatedTokenAddressSync,
} from "@solana/spl-token";
import { PublicKey, SystemProgram } from "@solana/web3.js";
import type { VestingParams } from ".";
import type { Ve33 } from "../types/ve_33";

export const vesting = async (
	program: Program<Ve33>,
	params: VestingParams,
) => {
	const { amount, authority, mintTokenProgramId } = params;
	const [mintToken] = PublicKey.findProgramAddressSync(
		[Buffer.from("mint")],
		mintTokenProgramId,
	);

	if (!amount || !authority || !mintTokenProgramId) {
		throw new Error("Invalid parameters");
	}

	const [convertPDA] = PublicKey.findProgramAddressSync(
		[Buffer.from("convert")],
		program.programId,
	);

	const convert = await program.account.convert.fetch(convertPDA);
	if (!convert) {
		throw new Error("Convert not initialized");
	}

	const [vestingPDA] = PublicKey.findProgramAddressSync(
		[
			Buffer.from("vesting"),
			authority.toBuffer(),
			new BN(convert.nextConvertIdentifier).toArrayLike(Buffer, "le", 8),
		],
		program.programId,
	);

	const vestingTokenAccount = getAssociatedTokenAddressSync(
		mintToken,
		authority,
	);

	const ix = await program.methods.createVesting(amount).accounts({
		funder: authority,
		convert: convertPDA,
		vesting: vestingPDA,
		vestingTokenAccount: vestingTokenAccount,
		vestingTokenMint: mintToken,
		systemProgram: SystemProgram.programId,
		tokenProgram: TOKEN_PROGRAM_ID,
		associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
	});

	return ix;
};
