"use client";

import { LOCALNET_RPC } from "@/configs/constants";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { clusterApiUrl } from "@solana/web3.js";
import { type PropsWithChildren, createContext, useContext, useMemo, useState } from "react";
export enum ClusterNetwork {
  Mainnet = "mainnet-beta",
  Testnet = "testnet",
  Devnet = "devnet",
  Localnet = "localnet",
}
export const CLUSTER_CHAINS = {
  [ClusterNetwork.Mainnet]: 101,
  [ClusterNetwork.Testnet]: 103,
  [ClusterNetwork.Devnet]: 102,
  [ClusterNetwork.Localnet]: 104
};
export interface Cluster {
  endpoint: string;
  network?: WalletAdapterNetwork;
}
export function toWalletAdaperNetwork(network: ClusterNetwork): WalletAdapterNetwork | undefined {
  switch (network) {
    case ClusterNetwork.Mainnet:
      return WalletAdapterNetwork.Mainnet;
    case ClusterNetwork.Testnet:
      return WalletAdapterNetwork.Testnet;
    case ClusterNetwork.Devnet:
      return WalletAdapterNetwork.Devnet;
    default:
      return undefined;
  }
}
export interface ClusterProviderContext {
  cluster: Cluster;
  network: ClusterNetwork;
  setNetwork: (network: ClusterNetwork) => void;
}
const ClusterProviderContext = createContext<ClusterProviderContext>({} as ClusterProviderContext);
export const ClusterProvider: React.FC<PropsWithChildren> = ({
  children
}) => {
  const [network, setNetwork] = useState<ClusterNetwork>(ClusterNetwork.Devnet);
  const cluster = useMemo(() => ({
    endpoint: network === ClusterNetwork.Localnet ? LOCALNET_RPC : clusterApiUrl(network),
    network: toWalletAdaperNetwork(network)
  }), [network]);
  return <ClusterProviderContext.Provider value={{
    cluster,
    setNetwork,
    network
  }}>
			{children}
		</ClusterProviderContext.Provider>;
};
export const useCluster = (): ClusterProviderContext => {
  const clusterContext = useContext(ClusterProviderContext);
  if (!clusterContext) {
    throw new Error("useCluster must be used within a ClusterProvider");
  }
  return clusterContext;
};